<template>
    <ListMobile v-if="isMobileAgent()" />
    <ListDesktop v-else />
</template>
  
<script>
export default {
    name: "Index",
    components: {
        ListDesktop: () => import('@/components/material/ListDesktop.vue'),
        ListMobile: () => import('@/components/material/ListMobile.vue'),
    },
}
</script>
  
<style scoped></style>
  